import React, { useEffect, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  OrderDetailsItem,
  OrderDetailsItemWrapper,
  OrderDetailsProductInfo,
  OrderDetailsProductInfoListItem,
  OrderDetailsProductInfoTotalItem,
  OrderDetailsProductInfoTotalRow,
  OrderDetailsStatus,
  PrescriptionDetail,
  PrescriptionMessageBody,
  PrescriptionMessageHeader,
  PrescriptionMessageWrapper,
  PrescriptionWrapper,
  ProductImageWrapper,
  PrescriptionSubmitWrapper,
  PrescriptionIconWrapper,
  StyledTrackButton,
  OrderDetailsStatusTextWrapper,
  EstimatedDeliveryDateWrapper,
} from './OrderDetails.style'
import {
  RECYCLABEL_FRAME_MACRO_MATERIALS,
  RECYCLABEL_LENS_MACRO_MATERIALS,
} from '../../../constants/product'
import { LinkAsButton } from '@components/UI'
import { getRxLensPrice } from '@utils/rx'
import {
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  ORDER_STATUSES,
  SHIPPED_STATUSES,
} from '../../../constants/order'
import { PRODUCT_PRICE_USAGE_NAMES, Price } from '../../../types/product'
import { RoundWarningIcon, RoundWarningSmallIcon } from '@components/UI/Icons'
import { PRESCRIPTION_VERIFICATION } from '../../../constants/routes'
import { formatPathWithBase } from '@utils/url'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useNavigate } from 'react-router'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'
import { OrderInfo } from './OrderInfo'
import { getOrderItemCatEntries, getOrderReturnedStatus } from '@utils/order'
import ProductImage from '@components/ProductImage/ProductImage'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { OriginalPrice } from '@components/order-recap/OrderRecapItemPrice.style'
import { useAppSelector } from '@hooks/redux'
import { catentriesSelector } from 'src/features/order/selector'
import ProductUtils from '@utils/ProductUtils'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import { IOrderDetails, OrderItem } from '../../../types/order'
import useLiveStock from '@hooks/useLiveStock'
import { EstimatedDeliveryDate } from '../../Cart/components/CartRecap/CartRecap.style'
import { useSelector } from 'react-redux'
import { loginStatusSelector } from '../../../redux/selectors/user'

interface OrderComponentProps {
  orderDetails: IOrderDetails
  orderItem: OrderItem
  //hasTrackingInformation: boolean
  //orderStatus: string
  trackUrlLink: string
  isTrackButtonVisible: boolean
}
export const OrderComponent: React.FC<OrderComponentProps> = ({
  orderItem,
  orderDetails,
  //hasTrackingInformation,
  //orderStatus,
  trackUrlLink,
  isTrackButtonVisible,
}) => {
  const navigate = useNavigate()
  const { basePath } = useStoreIdentity()
  const { isDesktop, isMobile } = useBreakpoints()
  const { t } = useTranslation()
  const theme = useTheme()
  const catentries = useAppSelector(catentriesSelector)
  const currentProduct = catentries?.[orderItem.productId]
  const loginStatus: boolean = useSelector(loginStatusSelector) || false

  /*const product = orderDetails.detailOrderItems?.find(
    ({ orderItemId }) => orderItem.orderItemId === orderItemId
  )*/

  const product = orderItem
  const [href, setHref] = useState(product?.seo?.href)

  useEffect(() => {
    //ref: https://luxotticaretail.atlassian.net/browse/GVI-957?focusedCommentId=881019
    if (!href && product?.xitem_display_catentry_id !== product.productId) {
      fetchCatentryInfo(product?.xitem_display_catentry_id)
    }
  }, [])

  const fetchCatentryInfo = async (id: string) => {
    const response = await getOrderItemCatEntries([id])
    const hrefFromResponse = response?.[id]?.seo?.href
    if (hrefFromResponse) {
      setHref(hrefFromResponse)
    }
  }

  if (!product) return null
  const productAttributes = product.productAttributes
  const { frameMaterialClass, frontColor, lensMaterialMacroClass, lensColor } =
    ProductAlgoliaUtils.getProductAttributes(productAttributes!)

  const onImageClicked = () => {
    const url = `${basePath}${href || ''}`
    navigate(url)
  }
  /*  const { orderItemExtendAttribute = [] } = orderItem*/

  const rxServices = orderItem?.roxableServices

  const frameMaterial = frameMaterialClass //getFrameMaterial(product)
  const isFrameMaterialRecyclabel = RECYCLABEL_FRAME_MACRO_MATERIALS.includes(
    frameMaterial?.toLowerCase()
  )
  const frameAttributesArray: string[] = [frontColor] //[getFrontColor(product)]
  if (isFrameMaterialRecyclabel) {
    frameAttributesArray.push(frameMaterial)
  }

  const nonRxLensMacroMaterial = lensMaterialMacroClass //getLensMacroMaterial(product)
  const nonRxLensAttributesArray: string[] = [lensColor] //[getLensesColor(product)]
  if (
    !rxServices &&
    isFrameMaterialRecyclabel &&
    RECYCLABEL_LENS_MACRO_MATERIALS.includes(
      nonRxLensMacroMaterial.toLowerCase()
    )
  ) {
    nonRxLensAttributesArray.unshift(nonRxLensMacroMaterial)
  }

  /*  const trackingNumber =
    orderItemExtendAttribute.find(
      (a) =>
        a.attributeName ===
        ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(
          orderDetails.orderId
        )
    )?.attributeValue || ''*/

  const FRAME_DETAILS = orderDetails?.x_data?.productDetails.find(
    ({ id }) => id === orderItem.productId
  )

  const RX_LENS_PRICE = rxServices ? getRxLensPrice(rxServices || []) : 0

  const PRICE = rxServices?.length
    ? {
        currency: orderItem?.currency,
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        value:
          parseFloat(FRAME_DETAILS?.x_offerpriceRx || '0') +
          (RX_LENS_PRICE || 0),
      }
    : {
        currency: orderItem?.currency,
        usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
        value: +orderItem?.orderItemPrice,
      }

  const returnedItemStatus = getOrderReturnedStatus(orderItem)
  const orderItemStatus = returnedItemStatus || orderItem.orderItemStatus

  const _initialPricePerBox = currentProduct
    ? ProductUtils.getInitialPrice(currentProduct.price as Price[])
    : null

  const CustomPrescriptionWrapper = () => (
    <PrescriptionWrapper>
      <PrescriptionDetail>
        <PrescriptionIconWrapper>
          {isDesktop ? (
            <RoundWarningIcon
              width={24}
              height={24}
              htmlColor={theme.palette.text.dark}
            />
          ) : (
            <RoundWarningSmallIcon htmlColor={theme.palette.text.dark} />
          )}
        </PrescriptionIconWrapper>

        <PrescriptionMessageWrapper>
          <PrescriptionMessageHeader>
            {t('Order.PrescriptionNeeded1')}
          </PrescriptionMessageHeader>
          <PrescriptionMessageBody>
            {t('Order.PrescriptionNeeded2')}
          </PrescriptionMessageBody>
        </PrescriptionMessageWrapper>
      </PrescriptionDetail>
      <PrescriptionSubmitWrapper>
        <LinkAsButton
          fillType="fill"
          to={formatPathWithBase(
            `${PRESCRIPTION_VERIFICATION}?orderId=${orderDetails?.orderId}`,
            basePath
          )}
        >
          {t('Order.PendingPrescriptionCta')}
        </LinkAsButton>
      </PrescriptionSubmitWrapper>
    </PrescriptionWrapper>
  )

  const itemDiscount =
    orderItem?.adjustment?.find((a) => a.usage === 'Discount')?.amount || 0

  const siblingItemDiscount =
    orderItem?.sibilingOrderItem?.adjustment?.find(
      (a) => a.usage === 'Discount'
    )?.amount || 0

  const totalDiscount = Math.abs(+itemDiscount) + Math.abs(+siblingItemDiscount)

  const updatedPrice =
    +orderItem.orderItemPrice +
    +(orderItem?.sibilingOrderItem?.orderItemPrice || 0) -
    totalDiscount

  const SHIPPED_AND_ORDER_STATUSES = [
    ...new Set([...SHIPPED_STATUSES, ...ORDER_STATUSES]),
  ]

  return (
    <OrderDetailsItemWrapper>
      {isDesktop &&
        [
          ORDER_ITEM_STATUS.PendingPrescription_V,
          ORDER_STATUS.PendingPrescription,
        ].includes(orderItemStatus) && <CustomPrescriptionWrapper />}
      {![
        ORDER_ITEM_STATUS.PendingPrescription_V,
        ORDER_STATUS.PendingPrescription,
      ].includes(orderItemStatus) && (
        <OrderDetailsStatus
          cancelled={[
            ORDER_ITEM_STATUS.Cancelled_X,
            ORDER_ITEM_STATUS.Cancelled_PrescriptionRejected,
          ].includes(orderItemStatus)}
        >
          <OrderDetailsStatusTextWrapper>
            <span>{t('Order.Status')}:</span>
            {t(`Order.Status_.${orderItemStatus}`)}
          </OrderDetailsStatusTextWrapper>
          {isTrackButtonVisible &&
            SHIPPED_AND_ORDER_STATUSES.includes(orderItemStatus) && (
              <TrackButton
                trackUrlLink={trackUrlLink}
                title={t('OrderDetails.Actions.TrackThisItem')}
              />
            )}
        </OrderDetailsStatus>
      )}

      {loginStatus && (
        <EstimatedDeliveryDateOrderComponent
          orderDetails={orderDetails}
          orderItem={orderItem}
        />
      )}

      <OrderDetailsItem>
        <ProductImageWrapper onClick={onImageClicked}>
          <ProductImage
            alt="Product image"
            attachments={product.attachments}
            sequence={'1.0'}
            partialProduct={product as any}
            width={300}
          />
        </ProductImageWrapper>
        <OrderDetailsProductInfo>
          <OrderInfo
            //product={product}
            orderItem={orderItem}
            //orderDetails={orderDetails}
          />
          <Divider style={{ width: '100%', marginTop: 'auto' }} />
          <OrderDetailsProductInfoTotalRow>
            <OrderDetailsProductInfoListItem>
              {t('Order.TotalPrice')}:
            </OrderDetailsProductInfoListItem>
            {/* {isContactLensesProduct(orderItem) ? (
              <OrderRecapItemPrice orderItem={orderItem} />
            ) : (*/}
            <OrderDetailsProductInfoTotalItem>
              {PRICE?.value > updatedPrice && (
                <OriginalPrice>
                  <FormattedPriceDisplay
                    min={PRICE?.value}
                    currency={orderItem.currency}
                  />
                </OriginalPrice>
              )}
              <FormattedPriceDisplay
                min={updatedPrice}
                currency={PRICE.currency}
              />
            </OrderDetailsProductInfoTotalItem>
            {/*)}*/}
          </OrderDetailsProductInfoTotalRow>

          {/*!!trackingNumber && (
            <>
              <OrderDetailsProductInfoRow>
                {t('Order.TrackingNumber')}: {trackingNumber}
              </OrderDetailsProductInfoRow>
            </>
          )*/}
        </OrderDetailsProductInfo>
      </OrderDetailsItem>

      {isMobile &&
        [
          ORDER_ITEM_STATUS.PendingPrescription_V,
          ORDER_STATUS.PendingPrescription,
        ].includes(orderItemStatus) && <CustomPrescriptionWrapper />}
    </OrderDetailsItemWrapper>
  )
}

const EstimatedDeliveryDateOrderComponent: React.FC<{
  orderDetails: IOrderDetails
  orderItem: OrderItem
}> = ({ orderDetails, orderItem }) => {
  const { t } = useTranslation()

  if (!orderDetails && !orderItem) return null
  const productAttributes = orderItem.productAttributes

  if (!productAttributes) return null
  const { lxSearchPageProductType } = ProductAlgoliaUtils.getProductAttributes(
    productAttributes!
  )
  const { isFrames } = ProductAlgoliaUtils.getLxProductTypes(
    lxSearchPageProductType
  )

  const {
    getEstimatedDeliveryDateFrames,
    getEstimatedDeliveryDateClOrCla = '',
  } = useLiveStock(orderDetails)

  const { xitem_product_id } = orderItem
  const currentEstimatedDeliveryDateLiveStock =
    getEstimatedDeliveryDateFrames(xitem_product_id) || ''

  const estimatedDeliveryDate = isFrames
    ? currentEstimatedDeliveryDateLiveStock
    : getEstimatedDeliveryDateClOrCla

  if (!estimatedDeliveryDate) return null

  return (
    <EstimatedDeliveryDateWrapper>
      {t('CartRecap.Labels.EstimatedDeliveryDate')}
      <EstimatedDeliveryDate>
        &nbsp;
        {estimatedDeliveryDate}
      </EstimatedDeliveryDate>
    </EstimatedDeliveryDateWrapper>
  )
}

export const TrackButton: React.FC<{ trackUrlLink: string; title: string }> = ({
  trackUrlLink,
  title,
}) => {
  if (!trackUrlLink) return null

  return (
    <StyledTrackButton
      to={trackUrlLink}
      external
      target="_blank"
      data-element-id="TrackMyOrder_CTA"
      data-description={title.split(' ').join('')}
    >
      {title}
    </StyledTrackButton>
  )
}
