import { elasticSearchApi } from '../elasticSearchApi'
import { Cart, IOrderDetails, Order } from '../../types/order'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'

export interface OrderTrackingPayload {
  orderId: string
  email?: string
}

export interface CopyOrderPayload {
  copyOrderItemId: string
  fromOrderId: string
  fromOrderId_0: string
  rxCopyEnabled: string
  newUserId?: string
}
export interface CopyOrderResponse {
  newOrderItemsCount: number
  orderId: string[]
  orderItemId: string[]
  viewTaskName: string
}

export interface OrderHistoryPayload {
  pageNumber: number
  pageSize?: number
  userId: string
  dateBefore?: string
}

export const extendedOrderApi = elasticSearchApi.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query<Cart, void>({
      providesTags: ['GetCart'],
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/store/{storeId}/cart/@self',
          method: 'GET',
        })

        return result.data
          ? { data: result.data as Cart }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    copyOrder: build.mutation<CopyOrderResponse, CopyOrderPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/store/{storeId}/cart/copy_order',
          method: 'POST',
          body: {
            ...args,
          },
        })

        return result.data
          ? { data: result.data as CopyOrderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    trackingOrder: build.mutation<IOrderDetails, OrderTrackingPayload>({
      invalidatesTags: ['GetProductDetails'],
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const body = {
          ...args,
        }
        const result = await fetchWithBQ({
          url: `/store/{storeId}/trackingorder/${args.orderId}/carrierUrl`,
          method: 'POST',
          body,
        })
        return result.data
          ? { data: result.data as IOrderDetails }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    findOrderById: build.query<
      IOrderDetails,
      { orderId: string; skipErrorSnackbar: boolean }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: `/store/{storeId}/order/${args.orderId}`,
          method: 'GET',
          queryParams: {
            skipErrorSnackbar: args.skipErrorSnackbar,
          },
        })

        return result.data
          ? { data: result.data as IOrderDetails }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    orderHistory: build.query<Order[], OrderHistoryPayload>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ({
          url: '/store/{storeId}/order/@history',
          method: 'GET',
          queryParams: {
            ...args,
          },
        })

        return result.data
          ? { data: result.data.Order as Order[] }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useTrackingOrderMutation,
  useGetCartQuery,
  useFindOrderByIdQuery,
  useCopyOrderMutation,
  useOrderHistoryQuery,
} = extendedOrderApi
