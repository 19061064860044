import React from 'react'
import {
  TrackOrderButtonAsLink,
  TrackOrderButtonSearchOtherOrder,
  TrackOrderDetailsAuthenticationWrapper,
  TrackOrderDetailsWrapper,
} from '../TrackOrder.style'
import { Trans, useTranslation } from 'react-i18next'
import {
  setOpenModalRegistration,
  setOpenModalSignIn,
} from '../../../features/ui/action'
import { OrderDetailsHeader } from '../../OrderHistory/OrderHistory.style'
import OrderDetails from '../../OrderHistory/components/OrderDetails'
import { useDispatch, useSelector } from 'react-redux'
import { loginStatusSelector } from '../../../redux/selectors/user'
import useBreakpoints from '@hooks/useBreakpoints'
import { IOrderDetails } from '../../../types/order'
import { LinkAsButton } from '@components/UI'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'

interface TrackOrderDetailsProps {
  orderDetails: IOrderDetails
  onSearchNewOrder: (value: boolean) => void
}

const TrackOrderDetails: React.FC<TrackOrderDetailsProps> = ({
  orderDetails,
  onSearchNewOrder,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loginStatus: boolean = useSelector(loginStatusSelector) || false
  const { isMobile } = useBreakpoints()
  const { langCode } = useStoreIdentity()

  const contactPageUrl = `/${langCode}/c/informazioni-utili/contatti`

  return (
    <TrackOrderDetailsWrapper>
      {!loginStatus && (
        <TrackOrderDetailsAuthenticationWrapper>
          <Trans
            i18nKey="TrackOrder.Labels.LoginAndRegister"
            components={[
              <TrackOrderButtonAsLink
                data-description={encodeURIComponent(
                  t('OrderConfirmation.Msgs.LoginCta')
                )}
                data-element-id="X_X_SignIn_Open"
                onClick={() => dispatch(setOpenModalSignIn(true))}
              />,
              <TrackOrderButtonAsLink
                data-description={encodeURIComponent(
                  t('OrderConfirmation.Msgs.CreateAnAccount')
                )}
                data-element-id="X_X_NewAccount_Open"
                onClick={() => dispatch(setOpenModalRegistration(true))}
              />,
            ]}
          />
        </TrackOrderDetailsAuthenticationWrapper>
      )}
      {isMobile && (
        <TrackOrderButtonSearchOtherOrder
          onClick={() => onSearchNewOrder(true)}
          style={{ marginRight: 'auto' }}
        >
          {t('TrackOrder.Labels.SearchAnotherOrder')}
        </TrackOrderButtonSearchOtherOrder>
      )}
      <OrderDetailsHeader>{t('Order.OrderDetails')}</OrderDetailsHeader>
      <OrderDetails trackingOrderDetails={orderDetails} />
      <TrackOrderButtonSearchOtherOrder
        onClick={() => onSearchNewOrder(true)}
        style={{ marginRight: 'auto' }}
      >
        {t('TrackOrder.Labels.SearchAnotherOrder')}
      </TrackOrderButtonSearchOtherOrder>
      <LinkAsButton
        style={{ marginRight: 'auto' }}
        target="_blank"
        data-element-id="TrackMyOrder_ContactUs"
        data-description="Contattaci"
        to={contactPageUrl}
        fillType={'outline'}
      >
        {t('Order.ContactUs')}
      </LinkAsButton>
    </TrackOrderDetailsWrapper>
  )
}

export default TrackOrderDetails
