import { useTrackingOrderMutation } from '../../features/order/query_new'
import {
  ITEM_DETAILS_PROFILE_NAME,
  PROFILE_NAME_PARAM,
} from '../../constants/common'
import { useGetProductDetailsByIdQuery } from '../../features/product/query'
import { IOrderDetails, OrderItem } from '../../types/order'
import { Product } from '../../types/product'

const useOrderDetailsTrackingOrder = () => {
  /*const {
    data: findOrderByIdData,
    isLoading: isLoadingOrderById,
    isFetching,
  } = useFindOrderByIdQuery(
    { orderId: orderId || '', skipErrorSnackbar: true },
    { skip: !orderId }
  )*/

  const [
    triggerTrackingOrder,
    { data: trackingOrderData, isLoading: isLoadingTrackingOrder },
  ] = useTrackingOrderMutation()

  const orderDetails = trackingOrderData || undefined
  const orderItem = orderDetails?.orderItem || []

  //getProductData
  const id = [...new Set(orderItem.map((i) => i.productId))]
  const parameters = {
    [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
    id: id,
  }
  const { data: getProductDetails, isLoading: isLoadingProducts } =
    useGetProductDetailsByIdQuery(parameters, {
      skip: !id.length,
    })

  const getOrderItemWithProducts = (
    orderItem: IOrderDetails['orderItem'],
    products: Product[]
  ) => {
    return orderItem.map((item) => mapProductDetailsToOrderItem(item, products))
  }

  const mapProductDetailsToOrderItem = (
    item: OrderItem,
    products: Product[]
  ) => {
    const product =
      products.find(
        (product) => String(product.id) === String(item.productId)
      ) || ({} as Product)
    return assignProductDetailsToOrderItem(item, product)
  }

  const assignProductDetailsToOrderItem = (
    item: OrderItem,
    product: Product
  ) => {
    const {
      name = '',
      productAttributes = {},
      attachments = [],
      seo = { href: '' },
      attributes = [],
    } = product
    return {
      ...item,
      name,
      productAttributes,
      attributes,
      attachments,
      seo,
    }
  }
  //OderDetails with products
  let orderDetailsWithProducts: IOrderDetails | undefined
  if (orderDetails && getProductDetails) {
    orderDetailsWithProducts = {
      ...orderDetails,
      detailedOrderItems: getOrderItemWithProducts(
        orderItem || [],
        getProductDetails || []
      ),
    }
  }
  return {
    triggerTrackingOrder,
    orderDetails: orderDetailsWithProducts,
    isLoading: Boolean(isLoadingTrackingOrder || isLoadingProducts),
  }
}

export default useOrderDetailsTrackingOrder
