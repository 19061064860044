import { styled } from '@mui/material/styles'
import {
  ButtonAsLink,
  LinkAsButton,
  StyledAnchor,
  StyledTypography,
} from '@components/UI'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const OrderDetailsWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  rowGap: theme.spacing(8),
  //marginBottom: theme.spacing(6),

  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6),
    rowGap: theme.spacing(6),
  },
}))

export const OrderDetailsLoaderWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'LoaderWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.color.grey.light}`,
  backgroundColor: theme.palette.color.white.main,
  rowGap: theme.spacing(8),
  padding: theme.spacing(6),
  marginBottom: theme.spacing(6),
  justifyContent: 'center',
  alignItems: 'center',
}))

export const StyledDetailsTitle = styled(StyledTypography, {
  name: 'OrderDetails',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  textTransform: 'none',

  span: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}))

export const StyledTrackButton = styled(LinkAsButton, {
  name: 'OrderDetails',
  slot: 'StyledTrackButton',
})(({}) => ({}))

export const StyledDetailsDownloadReceipt = styled(ButtonAsLink, {
  name: 'OrderDetails',
  slot: 'DownloadReceipt',
})(({ theme }) => ({
  fontSize: 14,
  height: 20,
  fontWeight: 600,
  width: 'fit-content',
  //margin: theme.spacing(2, 0),

  [getDesktopThemeBreakpoint(theme)]: {
    //float: 'right',
    //margin: 0,
  },
}))

export const DownloadReceiptWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'DownloadReceiptWrapper',
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 0),
  gap: theme.spacing(2),
}))

export const OnlineWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'OnlineWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(1, 0),
  color: theme.palette.color.grey.dark,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}))

export const OrderDetailsHeader = styled('div', {
  name: 'OrderDetails',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  //flewWrap: 'wrap',
  color: theme.palette.text.dark,
  padding: theme.spacing(6, 4, 0, 4),
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 0,
  },
}))

export const OrderDetailsHeaderDetails = styled('div', {
  name: 'OrderDetails',
  slot: 'HeaderDetails',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const OrderDetailsHeaderDetailsRight = styled('div', {
  name: 'OrderDetails',
  slot: 'HeaderDetailsRight',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  alignItems: 'flex-start',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-end',
  },
}))

export const PrescriptionWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'PrescriptionWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2.25, 0, 3.75, 6),
  backgroundColor: theme.palette.color.grey.lightest,
  rowGap: theme.spacing(2.5),
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
    padding: theme.spacing(4.25, 6.25, 4.25, 6),
    borderTop: `1px solid ${theme.palette.color.grey.light}`,
    borderRight: `1px solid ${theme.palette.color.grey.light}`,
    borderLeft: `1px solid ${theme.palette.color.grey.light}`,
  },
}))

export const OrderDetailsItemWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'ItemWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    //flexDirection: 'column-reverse',
  },
}))

export const PrescriptionMessageWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'MessageWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1.5),
  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(2),
  },
}))

export const PrescriptionSubmitWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'SubmitWrapper',
})(({ theme }) => ({
  display: 'flex',
  paddingRight: theme.spacing(6),
  [getDesktopThemeBreakpoint(theme)]: {
    paddingRight: 0,
  },
}))

export const PrescriptionIconWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'IconWrapper',
})(() => ({
  display: 'flex',
}))

export const PrescriptionMessageHeader = styled('span', {
  name: 'OrderDetails',
  slot: 'MessageHeader',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
}))

export const PrescriptionMessageBody = styled('span', {
  name: 'OrderDetails',
  slot: 'MessageBody',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.dark,
}))

export const PrescriptionDetail = styled('div', {
  name: 'OrderDetails',
  slot: 'PrescriptionDetail',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  columnGap: theme.spacing(4),
}))

export const OrderDetailsItemList = styled('div', {
  name: 'OrderDetails',
  slot: 'ItemList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 4),
  rowGap: theme.spacing(8),

  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(6),
    padding: 0,
  },
}))

export const OrderDetailsItem = styled('div', {
  name: 'OrderDetails',
  slot: 'Item',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `1px solid ${theme.palette.color.grey.light}`,
  padding: theme.spacing(3),
  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6),
    flexDirection: 'row',
    display: 'flex',
    columnGap: theme.spacing(6),
  },
}))

export const OrderDetailsItemSeparator = styled('div', {
  name: 'OrderDetails',
  slot: 'ItemSeparator',
})(({ theme }) => ({
  height: 1,
  backgroundColor: theme.palette.text.light,
  alignSelf: 'stretch',
}))

export const ProductImageWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductImageWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.white.main,
  //backgroundColor: theme.palette.color.grey.lightest,
  borderRadius: theme.spacing(4),
  marginBottom: theme.spacing(8),
  cursor: 'pointer',
  height: 150,

  img: {
    borderRadius: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },

  [getDesktopThemeBreakpoint(theme)]: {
    minWidth: 300,
    marginBottom: 0,
  },
}))

export const OrderDetailsProductInfo = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductInfo',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  //justifyContent: 'space-between',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2),
  [getDesktopThemeBreakpoint(theme)]: {
    rowGap: theme.spacing(3),
  },
}))

export const OrderDetailsProductInfoRow = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductInfoRow',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: theme.spacing(3),
  //borderBottom: `1px solid ${theme.palette.color.grey.light}`,
  width: '100%',
  //paddingBottom: theme.spacing(5),
  '&:last-child': {
    //borderBottom: 'none',
  },
}))

export const OrderDetailsProductInfoTotalRow = styled(
  OrderDetailsProductInfoRow,
  {
    name: 'OrderDetails',
    slot: 'DetailsProductInfoRow',
  }
)(() => ({
  justifyContent: 'space-between',
  width: '100%',
  //borderBottom: 'none',
  //borderTop: topBorder ? `1px solid ${theme.palette.color.grey.light}` : 'none',
  //paddingTop: theme.spacing(topBorder ? 5 : 0),
  //marginTop: 'auto',
}))

export const OrderDetailsProductInfoListItem = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductInfoListItem',
  shouldForwardProp: (prop) => prop !== 'isClProduct',
})<{ isClProduct?: boolean }>(({ theme, isClProduct }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  height: '100%',
  fontSize: theme.spacing(3.5),
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: isClProduct ? 'uppercase' : 'none',
}))

export const OrderDetailsProductInfoItem = styled('div', {
  name: 'OrderDetails',
  slot: 'ProductInfoItem',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: theme.spacing(3),
  color: theme.palette.text.dark,
}))

export const OrderDetailsProductInfoTotalItem = styled('span', {
  name: 'OrderDetails',
  slot: 'ProductInfoTotalItem',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  lineHeight: 1.5,
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const OrderDetailsPrice = styled('div', {
  name: 'OrderDetails',
  slot: 'Price',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  lineHeight: 'normal',
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const OrderDetailsShippingPaymentDetails = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetails',
})(({ theme }) => ({
  backgroundColor: theme.palette.color.grey.lightest,
  color: theme.palette.text.dark,
  padding: theme.spacing(6, 4),
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(4),

  'div:last-child > span:last-child ': {
    textTransform: 'uppercase',
  },

  [getDesktopThemeBreakpoint(theme)]: {
    padding: theme.spacing(6, 8),
    width: '50%',
  },
}))

export const OrderDetailsShippingPaymentDetailsTitle = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetailsTitle',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}))

export const OrderDetailsShippingPaymentDetailsInfo = styled('div', {
  name: 'OrderDetails',
  slot: 'ShippingPaymentDetailsInfo',
})(() => ({
  fontSize: 12,
  fontWeight: 600,
}))

export const OrderDetailsFooter = styled('div', {
  name: 'OrderDetails',
  slot: 'Footer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [getDesktopThemeBreakpoint(theme)]: {
    flexDirection: 'row',
  },
}))

export const OrderDetailsPriceDetailsWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'PriceDetailsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  rowGap: theme.spacing(2),
  padding: theme.spacing(6, 4),
  maxHeight: 184,

  [getDesktopThemeBreakpoint(theme)]: {
    width: '50%',
    padding: theme.spacing(6, 0, 6, 8),
  },
}))

export const OrderDetailsItemDetailsRow = styled('div', {
  name: 'OrderDetails',
  slot: 'DetailsRow',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'stretch',
  flexGrow: 0,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: 0,
}))

export const OrderDetailsFooterItem = styled('span', {
  name: 'OrderDetails',
  slot: 'ShippingDetails',
})(({ theme }) => ({
  fontSize: theme.spacing(3),
  lineHeight: 1.33,
  color: theme.palette.text.dark,
  width: '50%',
  [getDesktopThemeBreakpoint(theme)]: {
    width: '60%',
  },
}))

export const OrderDetailsListItem = styled(OrderDetailsFooterItem, {
  name: 'OrderDetails',
  slot: 'ListShippingDetails',
})(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 600,
  width: '50%',
  [getDesktopThemeBreakpoint(theme)]: {
    width: 'auto',
  },
}))

export const OrderDetailsBillingAndPaymentListItem = styled(
  OrderDetailsFooterItem,
  {
    name: 'OrderDetails',
    slot: 'BillingAndPaymentListItem',
  }
)(() => ({
  fontWeight: 600,
  maxWidth: 82,
  textWrap: 'wrap',
}))

export const OrderDetailsPriceDetailsItem = styled('span', {
  name: 'OrderDetails',
  slot: 'PriceDetailsItem',
})(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  lineHeight: 1.43,
  textAlign: 'right',
  color: theme.palette.text.dark,
}))

export const OrderDetailsPriceDetailsTotalItem = styled('span', {
  name: 'OrderDetails',
  slot: 'PriceDetailsTotalItem',
})(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  lineHeight: 1.5,
  textAlign: 'right',
  color: theme.palette.text.dark,
}))

export const OrderDetailsCLInfoContainer = styled('div', {
  name: 'OrderDetails',
  slot: 'CLInfoContainer',
})(() => ({
  display: 'flex',
  width: '100%',
}))

export const OrderDetailsCLInfoColum = styled('div', {
  name: 'OrderDetails',
  slot: 'CLInfoColum',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const OrderDetailsCLInfoHeader = styled('span', {
  name: 'OrderDetails',
  slot: 'CLInfoHeader',
})(({ theme }) => ({
  marginRight: theme.spacing(5),
  marginTop: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 16,
}))

export const OrderDetailsCLInfoValue = styled('span', {
  name: 'OrderDetails',
  slot: 'CLInfoValue',
})(({ theme }) => ({
  marginRight: theme.spacing(7),
  marginTop: theme.spacing(1),
  fontSize: 16,
}))

export const OrderDetailsStatus = styled('div', {
  name: 'OrderHistoryDetails',
  slot: 'Status',
  //shouldForwardProp: (prop) => prop !== 'shipped',
  shouldForwardProp: (prop) => prop !== 'cancelled',
})<{ cancelled: boolean }>(({ theme, cancelled }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(4, 6),
  border: `1px solid ${theme.palette.color.grey.light}`,
  borderBottom: 'none',
  //borderTop: 'none',
  fontWeight: theme.typography.fontWeightBold,
  color: cancelled ? theme.palette.error.main : theme.palette.success.main,

  a: {
    width: '100%',
  },

  span: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
    color: theme.palette.text.dark,
  },

  [theme.breakpoints.up('md')]: {
    //borderTop: `1px solid ${theme.palette.color.grey.light}`,
    //borderBottom: 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    a: {
      width: 'auto',
    },
  },
  // [theme.breakpoints.down('md')]: {
  //   borderTop: 'none',
  // },
}))

export const OrderDetailsStatusTextWrapper = styled('div', {
  name: 'OrderHistoryDetails',
  slot: 'TextWrapper',
})(() => ({}))

export const ProductPriceInitialPrice = styled('span', {
  name: 'OrderComponent',
  slot: 'InitialPrice',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  fontSize: 14,
  textDecoration: 'line-through',
}))

export const OrderReturnCopy = styled('div', {
  name: 'OrderDetails',
  slot: 'ReturnCopy',
})(({ theme }) => ({
  color: theme.palette.text.dark,
  margin: theme.spacing(0, 4),
  textAlign: 'justify',

  [theme.breakpoints.up('md')]: {
    margin: 0,
  },

  a: {
    color: theme.palette.text.dark,
  },
}))

export const OrderDetailsShipToStoreCompleteWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'ShipToStoreCompleteWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 6),
  backgroundColor: theme.palette.color.backgrounds.color3,
  gap: theme.spacing(4),
  color: theme.palette.text.dark,
}))

export const OrderDetailsShipToStoreContentLink = styled('div', {
  name: 'OrderDetails',
  slot: 'ShipToStoreContentLink',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(6.5),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(0),
  },
}))

export const OrderDetailsContentMapLink = styled('div', {
  name: 'OrderDetails',
  slot: 'ContentMapLink',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 'bold',
  width: '100%',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    gap: theme.spacing(12),
  },
}))

export const OrderDetailsShipToStorePhoneLink = styled(StyledAnchor, {
  name: 'OrderDetails',
  slot: 'ShipToStorePhoneLink',
})(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.color.black.main,
}))

export const OrderDetailsShipToStoreTextBody = styled(StyledTypography, {
  name: 'OrderDetails',
  slot: 'ShipToStorePhoneLink',
})(({ theme }) => ({
  color: theme.palette.color.black.main,
}))

export const EstimatedDeliveryDateWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'EstimatedDeliveryDateWrapper',
})(({ theme }) => ({
  /*  display: 'flex',
  flexDirection: 'column',*/
  padding: theme.spacing(1.5, 4),
  backgroundColor: theme.palette.color.backgrounds.color4,
  borderLeft: `1px solid ${theme.palette.color.grey.light}`,
  borderRight: `1px solid ${theme.palette.color.grey.light}`,

  color: theme.palette.text.dark,
  fontSize: 14,
  span: {
    fontWeight: 'bold',
  },
}))

export const OrderDetailsReorderButtonWrapper = styled('div', {
  name: 'OrderDetails',
  slot: 'ReorderButtonWrapper',
})(({ theme }) => ({
  //width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(4, 6),
  border: `1px solid ${theme.palette.color.grey.light}`,
  borderTop: 'none',
}))
