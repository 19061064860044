import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Contact, RegistrationFormData } from '../../../types/user'
import { useYupValidationResolver } from '../../../utils/validationResolver'
import { buildYupValidationSchema } from '../../../constants/form'
import { REGISTRATION_FORM_FIELDS } from '../../../constants/user'
import Axios, { Canceler } from 'axios'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  authenticationTypeErrorSelector,
  firstAvailableContactInformationSelector,
  guestStatusSelector,
  pendingRegistrationStatusSelector,
  registrationStatusSelector,
} from '../../../redux/selectors/user'
import * as userAction from '../../../redux/actions/user'
import { Trans, useTranslation } from 'react-i18next'
import { useSite } from '../../../foundation/hooks/useSite'
import {
  orderDetailsSelector,
  orderItemsSelector,
} from '../../../features/order/selector'
import {
  Button,
  DatePicker,
  IconButton,
  NewPassword,
  StyledCheckbox,
  TextField,
} from '../../UI'
import isEmpty from 'lodash/isEmpty'
import { PasswordHideIcon, PasswordShowVisibleIcon } from '../../UI/Icons'
import { useTheme } from '@mui/material'
import {
  DateHelperText,
  DateTextField,
  PrivacyPolicyErrorMessage,
  RegistrationFormControlLabel,
  StyledPolicyLink,
  StyledPrivacyCheckboxes,
} from '../../../pages/SignUp/styles/RegistrationLayout.style'
import {
  ContentButton,
  ContentTextField,
  StyledForm,
  WrapperRegistration,
} from './RegistrationFormDialog.style'
import { storeCfgServiceLinksSelector } from '../../../redux/selectors/site'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import format from 'date-fns/format'
import { genericErrorSelector } from '../../../redux/selectors/error'
import { ERROR_EMAIL_ALREADY_REGISTERED } from '../../../constants/errors'
import { AuthenticationErrorType } from '../../../redux/reducers'
import { setOpenModalRegistration } from '../../../features/ui/action'

const defaultValues: RegistrationFormData = {
  firstName: '',
  lastName: '',
  email: '',
  email2: '',
  dateOfBirth: '',
  password1: '',
  password2: '',
  privacy1: false,
  privacy2: false,
}

const RegistrationFormDialog: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const theme = useTheme()
  const orderItems = useSelector(orderItemsSelector)
  const userIsGuest = !!useSelector(guestStatusSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const registrationError: any = useSelector(genericErrorSelector)
  const authenticationTypeError = useSelector(authenticationTypeErrorSelector)
  const registrationStatus = !!useSelector(registrationStatusSelector)
  const contactInformation: Contact = useSelector(
    firstAvailableContactInformationSelector,
    shallowEqual
  )
  const pendingRegistrationStatus = !!useSelector(
    pendingRegistrationStatusSelector
  )
  const { basePath } = useStoreIdentity()
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [inputType, setInputType] = useState<string>('password')
  //const [bodyData, setBodyData] = useState<object | undefined>()

  let errorMessage = null
  const errorCode = registrationError.errorCode
  // error code for already registered email
  if (
    errorCode === ERROR_EMAIL_ALREADY_REGISTERED &&
    authenticationTypeError === AuthenticationErrorType.register
  ) {
    let errorKey = registrationError.errorKey || ''
    if (errorCode && errorCode !== errorKey) {
      errorKey = `${errorKey}_${errorCode}`
    }
    errorMessage = errorKey && t(`error-message.${errorKey}`)
  }

  const storeId: string = mySite ? mySite.storeID : ''
  const catalogId: string = mySite ? mySite.catalogID : ''
  const preferredLanguage: string = mySite ? mySite.langId : ''

  const optInBoxes: boolean[] | undefined = mySite
    ? mySite.xStoreCfg?.optInBoxes
    : []
  const [check1, check2, check3] = optInBoxes || []

  const privacyLink = basePath + serviceLinks?.privacy

  const {
    setValue,
    handleSubmit,
    //watch,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: useYupValidationResolver(
      buildYupValidationSchema({
        formFields: REGISTRATION_FORM_FIELDS,
        i18nInvalidMsgBase: 'RegistrationLayout.Msgs.',
        i18nFormFieldsInvalidMsgs: {},
        t,
      })
    ),
  })
  const newPasswordLabel = t('RegistrationLayout.Password')

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  /*  useEffect(() => {
    if (bodyData) {
      dispatch(
        userAction.registrationAction({
          body: bodyData,
          ...payloadBase,
        })
      )
      dispatch(setOpenModalRegistration(false))
    }
  }, [bodyData, dispatch])*/
  useEffect(() => {
    if (registrationStatus) {
      dispatch(setOpenModalRegistration(false))
    }
  }, [registrationStatus])

  useEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  useEffect(() => {
    if (contactInformation) {
      const { lastName, firstName, email1 } = contactInformation
      setValue('lastName', lastName)
      setValue('firstName', firstName)
      setValue('email', email1, { shouldValidate: true })
    }
  }, [contactInformation])

  useEffect(() => {
    if (orderItems && orderItems[0] && userIsGuest) {
      const { lastName, firstName, email1 } = orderItems[0]
      const paymentEmail =
        orderDetails?.paymentInstruction
          ?.find((pi) => !!pi)
          ?.protocolData?.find((el) => el.name === 'billTo_email')?.value || ''
      const email = email1 ? email1 : paymentEmail
      !!lastName && setValue('lastName', lastName)
      !!firstName && setValue('firstName', firstName)
      setValue('email', email, { shouldValidate: true })
    }
  }, [orderItems, userIsGuest, orderDetails])

  const onSubmit = async (data: RegistrationFormData) => {
    const {
      firstName,
      lastName,
      password1,
      password2,
      email,
      receiveEmail,
      privacy1,
      privacy2,
      privacy3,
      dateOfBirth,
    } = data

    const body = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      logonId: email,
      logonPassword: password1,
      logonPasswordVerify: password2,
      registerType: 'G',
      profileType: 'C',
      email1: email,
      dateOfBirth: format(new Date(dateOfBirth), 'yyyy-MM-dd'),
      phone1: '',
      storeId,
      catalogId,
      preferredLanguage,
      preferredCurrency: '',
      receiveEmail: receiveEmail,
      receiveEmailPreference: [
        {
          value: receiveEmail,
          storeID: storeId,
        },
      ],
      challengeQuestion: '-',
      challengeAnswer: '-',
      x_optIn: {
        x_privacy: !check1 ? true : privacy1,
        x_newsLetter: !check2 ? true : privacy2,
        x_profiling: !check3 ? true : !!privacy3,
      },
    }
    await dispatch(
      userAction.registrationAction({
        body: body,
        ...payloadBase,
      })
    )
    //setTimeout(() => setBodyData(body), 100)
  }

  return (
    <WrapperRegistration>
      <StyledForm
        noValidate
        name="registrationForm"
        id={'registration_form_5_sign-in-registration-page-new-registration'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ContentTextField>
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                inputProps={{ maxLength: 20 }}
                autoFocus
                required
                fullWidth
                label={t('RegistrationLayout.FirstName')}
                error={(error && !!value?.length) || (error && isEmpty(value))}
                helperText={
                  ((error && !!value?.length) || (error && isEmpty(value))) &&
                  t(errors.firstName?.message ?? '')
                }
                showvalidationstatus
                isvalid={!error && !!value?.length}
                ispristine={!value?.length}
              />
            )}
            name="firstName"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                //margin="normal"
                inputProps={{ maxLength: 20 }}
                required
                fullWidth
                label={t('RegistrationLayout.LastName')}
                error={(error && !!value?.length) || (error && isEmpty(value))}
                helperText={
                  ((error && !!value?.length) || (error && isEmpty(value))) &&
                  t(errors.lastName?.message ?? '')
                }
                showvalidationstatus
                isvalid={!error && !!value?.length}
                ispristine={!value?.length}
              />
            )}
            name="lastName"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                required
                fullWidth
                label={t('RegistrationLayout.Email')}
                autoComplete="email"
                inputProps={{
                  maxLength: 100,
                  type: 'email',
                  placeholder: 'name@domain.com',
                }}
                error={!!error || !!errorMessage}
                helperText={
                  ((errors && t(errors.email?.message ?? '')) ||
                    errorMessage) ??
                  ''
                }
                showvalidationstatus
                isvalid={!error && !!value?.length && !errorMessage}
                ispristine={!value?.length}
              />
            )}
            name="email"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                required
                fullWidth
                label={t('RegistrationLayout.ConfirmEmail')}
                autoComplete="email2"
                inputProps={{
                  maxLength: 100,
                  type: 'email',
                  placeholder: 'name@domain.com',
                }}
                error={(error && !!value?.length) || (error && isEmpty(value))}
                helperText={
                  ((error && !!value?.length) || (error && isEmpty(value))) &&
                  t(errors.email2?.message ?? '')
                }
                showvalidationstatus
                isvalid={!error && !!value?.length}
                ispristine={!value?.length}
              />
            )}
            name="email2"
            control={control}
            defaultValue=""
          />
          <Controller
            name={'dateOfBirth'}
            control={control}
            render={({
              field: { onBlur, onChange, value, ref, ...rest },
              fieldState: { error },
            }) => {
              const date = new Date(value)
              return (
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  error={Boolean(error)}
                  onAccept={() => {
                    onBlur()
                  }}
                  onChange={(e) => {
                    // this is because new Date(null) returns 1970
                    // instead of empty string
                    onChange(e === null ? undefined : e)
                  }}
                  disableFuture
                  views={['year', 'day']}
                  value={date}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <DateTextField
                      {...params}
                      name={'date-picker'}
                      error={!!error}
                      onFocus={() => clearErrors('dateOfBirth')}
                      label={t('RegistrationLayout.DateOfBirth')}
                      helperText={
                        <>
                          {!error && (
                            <DateHelperText>
                              {t('RegistrationLayout.DateOfBirthHelper')}
                            </DateHelperText>
                          )}
                          {t(
                            error?.type === 'typeError'
                              ? 'RegistrationLayout.Msgs.InvalidDateType'
                              : error?.message
                              ? error.message
                              : ''
                          )}
                        </>
                      }
                      showvalidationstatus={true}
                    />
                  )}
                />
              )
            }}
          />
          <Controller
            render={({ field }) => (
              <NewPassword
                {...field}
                isPristine={!field.value.length}
                error={!!errors.password1}
                label={newPasswordLabel}
              />
            )}
            name="password1"
            control={control}
            defaultValue=""
          />
          <Controller
            render={({
              field: { value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                {...rest}
                inputRef={ref}
                value={value}
                fullWidth
                label={t('RegistrationLayout.VerifyPassword')}
                autoComplete="new-password"
                inputProps={{
                  maxLength: 50,
                }}
                type={inputType}
                customInputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    >
                      {showPassword ? (
                        <PasswordShowVisibleIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      ) : (
                        <PasswordHideIcon
                          htmlColor={theme.palette.primary.main}
                        />
                      )}
                    </IconButton>
                  ),
                }}
                error={(error && !!value?.length) || (error && isEmpty(value))}
                helperText={
                  ((error && !!value?.length) || (error && isEmpty(value))) &&
                  t(errors.password2?.message ?? '')
                }
                showvalidationstatus
                isvalid={!error && !!value?.length}
                ispristine={!value?.length}

                // error={!!errors.password2}
                // helperText={t(errors.password2?.message ?? '')}
              />
            )}
            name="password2"
            control={control}
            defaultValue=""
          />
          {check1 && (
            <RegistrationFormControlLabel
              {...register('privacy1', {
                validate: (value) => value === true,
              })}
              control={
                <StyledCheckbox
                  color="primary"
                  onChange={(e) => setValue('privacy1', e.target.checked)}
                />
              }
              label={
                <StyledPrivacyCheckboxes variant="caption">
                  <Trans i18nKey="RegistrationLayout.PrivacyPolicy1">
                    <StyledPolicyLink to={privacyLink} target="_blank" />
                  </Trans>
                  {errors.privacy1?.message && (
                    <PrivacyPolicyErrorMessage>
                      {t(errors.privacy1?.message)}
                    </PrivacyPolicyErrorMessage>
                  )}
                </StyledPrivacyCheckboxes>
              }
            />
          )}
          {check2 && (
            <div>
              <RegistrationFormControlLabel
                {...register('privacy2')}
                control={
                  <StyledCheckbox
                    color="primary"
                    onChange={(e) => setValue('privacy2', e.target.checked)}
                  />
                }
                label={
                  <StyledPrivacyCheckboxes variant="caption">
                    <div>{t('RegistrationLayout.PrivacyPolicy2')}</div>
                    <Trans i18nKey="RegistrationLayout.PrivacyPolicy2CTA">
                      <StyledPolicyLink to={privacyLink} target="_blank" />
                    </Trans>
                  </StyledPrivacyCheckboxes>
                }
              />
            </div>
          )}
        </ContentTextField>
        <ContentButton>
          <Button
            data-element-id="X_X_AccountCreate_Submit"
            type="submit"
            variant="primary"
            loading={pendingRegistrationStatus}
          >
            {t('RegistrationLayout.Submit')}
          </Button>
        </ContentButton>
      </StyledForm>
    </WrapperRegistration>
  )
}

export default RegistrationFormDialog
