import React, { useEffect, useState } from 'react'
import {
  ContentLandingTitle,
  LandingPageTitle,
  WrapperLandingTitle,
} from '../CmsPages/LadingPage/LandingPage'
import { useTranslation } from 'react-i18next'

import { OrderTrackingPayload } from '../../features/order/query_new'
import SignInDialog from '@components/SignInDialog'

import RegistrationDialog from '@components/RegistrationDialog'

import { useGetHclPageQuery } from '../../features/cms/query'
import PlacementLayout from '@components/Cms/PlacementLayout'
import { sendStaticEvent } from '../../foundation/analytics/tealium/lib'
import { useAnalyticsData } from '../../foundation/analytics/hooks/useAnalyticsData'
import TrackOrderForm from './components/TrackOrderForm'
import useOrderDetailsTrackingOrder from './useOrderDetailsTrackingOrder'
import TrackOrderDetails from './components/TrackOrderDetails'

const TrackOrder: React.FC = () => {
  const { t } = useTranslation()
  const cmsAnalyticsData = useAnalyticsData('page')
  const [searchNewOrder, setNewSearchOrder] = useState<boolean>(false)
  const { data, isLoading: isLoadingCms } = useGetHclPageQuery('traccia-ordine')
  const { orderDetails, triggerTrackingOrder, isLoading } =
    useOrderDetailsTrackingOrder()

  const placements = data?.commercePlacements! || []
  /*const orderId = orderDetails?.orderId || ''
  const email = orderDetails?.orderItem?.at(0)?.email1 || ''
  const { data } = useCheckRegisterEmailQuery(
    { orderId: orderId, email: email },
    { skip: !orderId && !email }
  )*/

  const handleTrackingOrder = async (payload: OrderTrackingPayload) => {
    return await triggerTrackingOrder(payload).unwrap()
  }

  const onSearchNewOrder = (value: boolean) => {
    setNewSearchOrder(value)
    if (value) {
      const goTop = document?.getElementById('trackMyOrderId')!
      goTop.scrollIntoView({
        behavior: 'smooth',
        //block: 'start',
        //inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    sendStaticEvent({
      common: cmsAnalyticsData,
      page_Section1: 'Other',
      page_Section2: 'TrackMyOrder',
    })
  }, [placements.length])

  return (
    <>
      <WrapperLandingTitle id={'trackMyOrderId'}>
        <ContentLandingTitle>
          <LandingPageTitle variant={'h1'}>
            {t('TrackOrder.Labels.Title')}
          </LandingPageTitle>
        </ContentLandingTitle>
      </WrapperLandingTitle>
      {orderDetails && !searchNewOrder ? (
        <>
          <TrackOrderDetails
            orderDetails={orderDetails}
            onSearchNewOrder={onSearchNewOrder}
          />
          <SignInDialog />
          <RegistrationDialog />
        </>
      ) : (
        <>
          <PlacementLayout placements={placements} loading={isLoadingCms} />
          <TrackOrderForm
            trackingOrder={handleTrackingOrder}
            isLoading={isLoading}
            onSearchNewOrder={onSearchNewOrder}
          />
        </>
      )}
    </>
  )
}

export default TrackOrder
