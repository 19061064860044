import React, { useState } from 'react'
import { OrderTrackingPayload } from '../../../features/order/query_new'
import { IOrderDetails } from '../../../types/order'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  loginStatusSelector,
  userIdSelector,
} from '../../../redux/selectors/user'
import * as yup from 'yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getGuestIdentity } from '../../../features/user/thunks'
import {
  TrackOrderErrorMessage,
  TrackOrderFieldsAndErrorsWrapper,
  TrackOrderFieldsWrapper,
  TrackOrderFormWrapper,
  TrackOrderTextFieldContent,
} from '../TrackOrder.style'
import { Button, StyledTypography, TextField } from '@components/UI'

interface TrackOrderFormData {
  orderNumber: string
  email: string
}

interface TrackOrderFormProps {
  trackingOrder: (payload: OrderTrackingPayload) => Promise<IOrderDetails>
  isLoading: boolean
  onSearchNewOrder: (value: boolean) => void
}

const TrackOrderForm: React.FC<TrackOrderFormProps> = ({
  trackingOrder,
  isLoading,
  onSearchNewOrder,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loginStatus: boolean = useSelector(loginStatusSelector) || false
  const userId: string = useSelector(userIdSelector) || ''
  const [errorResponseEnabled, setErrorResponseEnabled] =
    useState<boolean>(false)

  const schema = yup.object().shape({
    orderNumber: yup.string().required(t('TrackOrder.Errors.EmptyOrderNumber')),
    email: loginStatus
      ? yup.string().email(t('TrackOrder.Errors.InvalidEmail'))
      : yup
          .string()
          .email(t('TrackOrder.Errors.InvalidEmail'))
          .required(t('TrackOrder.Errors.EmptyEmail')),
  })

  const { handleSubmit, control } = useForm<TrackOrderFormData>({
    defaultValues: {
      orderNumber: '',
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<TrackOrderFormData> = async (data) => {
    try {
      setErrorResponseEnabled(false)

      if (!userId) await dispatch(getGuestIdentity())

      const trackingOrderPayload: OrderTrackingPayload = {
        orderId: data.orderNumber.trim(),
      }

      if (data.email)
        trackingOrderPayload.email = data.email.toLowerCase().trim()

      await trackingOrder(trackingOrderPayload)
      onSearchNewOrder(false)
    } catch (e) {
      setErrorResponseEnabled(true)
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TrackOrderFormWrapper>
        <TrackOrderFieldsAndErrorsWrapper>
          <TrackOrderFieldsWrapper>
            <TrackOrderTextFieldContent>
              <Controller
                name="orderNumber"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="none"
                      name="orderNumber"
                      label={t('TrackOrder.Labels.OrderNumber')}
                      value={value}
                      showvalidationstatus
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      error={!!error || errorResponseEnabled}
                      isvalid={
                        !error && !!value?.length && !errorResponseEnabled
                      }
                    />
                  )
                }}
              />
            </TrackOrderTextFieldContent>
            <TrackOrderTextFieldContent>
              <Controller
                name="email"
                control={control}
                //rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="none"
                      name="email"
                      label={t('TrackOrder.Labels.Email')}
                      value={value}
                      showvalidationstatus
                      type="email"
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      error={!!error || errorResponseEnabled}
                      isvalid={
                        !error && !!value?.length && !errorResponseEnabled
                      }
                    />
                  )
                }}
              />
            </TrackOrderTextFieldContent>
          </TrackOrderFieldsWrapper>
          {errorResponseEnabled && (
            <TrackOrderErrorMessage>
              <StyledTypography fontSize={12}>
                {t('TrackOrder.Errors.Generic')}
              </StyledTypography>
            </TrackOrderErrorMessage>
          )}
        </TrackOrderFieldsAndErrorsWrapper>
        <Button
          data-element-id="TrackMyOrder_FindMyOrder"
          data-description="Trovailmioordine"
          style={{ marginRight: 'auto' }}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('TrackOrder.Labels.Submit')}
        </Button>
      </TrackOrderFormWrapper>
    </form>
  )
}

export default TrackOrderForm
