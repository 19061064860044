import { styled } from '@mui/material/styles'
import { Button, ButtonAsLink, StyledTypography } from '@components/UI'

export const TrackOrderFormWrapper = styled('div', {
  name: 'TrackOrder',
  slot: 'FormWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  padding: theme.spacing(0, 4, 10),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(10),
    padding: theme.spacing(0, 44, 16),
  },
}))

export const TrackOrderFieldsAndErrorsWrapper = styled('div', {
  name: 'TrackOrder',
  slot: 'FieldsAndErrorsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),
    width: '66%',
  },
}))

export const TrackOrderFieldsWrapper = styled('div', {
  name: 'TrackOrder',
  slot: 'FieldsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(10),
  },
}))

export const TrackOrderTextFieldContent = styled('div', {
  name: 'TrackOrder',
  slot: 'TextFieldContent',
})(({ theme }) => ({
  padding: theme.spacing(4, 0),
  width: '100%',
}))

export const TrackOrderErrorMessage = styled(StyledTypography, {
  name: 'TrackOrder',
  slot: 'ErrorMessage',
})(({ theme }) => ({
  color: theme.palette.error.main,
  [theme.breakpoints.up('md')]: {
    width: '55%',
  },
}))

export const TrackOrderDetailsWrapper = styled('div', {
  name: 'TrackOrder',
  slot: 'DetailsWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 4),
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 88, 16, 44),
    div: {
      //width: '100%',
    },
  },
}))

export const TrackOrderDetailsAuthenticationWrapper = styled('div', {
  name: 'TrackOrder',
  slot: 'DetailsAuthenticationWrapper',
})(() => ({}))

export const TrackOrderButtonAsLink = styled(ButtonAsLink, {
  name: 'TrackOrder',
  slot: 'Button',
})(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  '&::after': {
    bottom: 4,
    background: theme.palette.primary.main,
  },
}))

export const TrackOrderButtonSearchOtherOrder = styled(Button, {
  name: 'TrackOrder',
  slot: 'ButtonSearchOtherOrder',
})(() => ({}))
